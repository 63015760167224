<template>
    <div class="mb-5">
        <div class="row">
            <div class="col-lg-3" v-for="item in selectedBreeds" v-if="item.breed">
                <div class="form-group ml-4">
                    <input type="checkbox" class="custom-control-input" :id="'breed-' + item.id" name="services[]" :value="item.id" checked>
                    <label class="custom-control-label" :for="'breed-' + item.id">{{ item.name }}</label>
                </div>
            </div>
        </div>
        <div class="form-group">
            <input type="text" v-model="search" class="form-control rounded-0" placeholder="Įrašykite veislės pavadinimą">
        </div>
        <table class="table">
            <tr v-for="item, index in filteredBreeds" v-if="search != '' && index < 5 && item.breed">
                <td>{{ item.name }}</td>
                <td class="text-right"><button type="button" class="btn btn-primary btn-sm" @click="addBreed(item)"><i class="fal fa-plus"></i></button></td>
            </tr>
        </table>
        <hr>
        <h3>Nerandi veislės?</h3>
        <p v-if="!new_serice">
            <a href="#new" @click="new_serice = true"><i class="fal fa-arrow-right mr-2"></i>Įrašykite savo</a>
        </p>
        <div class="form-group" v-if="new_serice">
            <label>Veislės pavadinimas <small>(galite įrašyti daugiau nei vieną, kiekvieną atskiriant kableliu)</small> </label>
            <input type="text" class="form-control rounded-0" v-model="new_services" name="new_services">
        </div>
    </div>
</template>

<script>
    export default{
        props: ['breeds', 'listing_breeds', 'category_id'],
        data(){
            return {
                search: '',
                breeds_list: null,
                new_serice: true,
                new_services: null,
                selectedBreeds: []
            }
        },
        mounted(){
            this.breeds_list = this.breeds
            this.listing_breeds.forEach(item => {
                if(item.category_id == this.category_id){
                    this.selectedBreeds.push(item)
                }
            })
        },
        methods:{
            addBreed(item){
                this.selectedBreeds.push(item)
                this.search = ''
            }
        },
        computed:{
            filteredBreeds(){
                return this.breeds.filter(item => {
                    return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
                })
            }
        }
    }
</script>
