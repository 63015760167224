<template>
    <div>
        <button type="button" class="btn btn-outline-primary btn-sm rounded-0" data-toggle="modal" data-target="#workHourModal">
            <i class="fal fa-clock mr-2"></i> Nurodyti darbo valandas
        </button>
        <div class="modal fade" id="workHourModal" tabindex="-1" role="dialog" aria-labelledby="workHourModalLabel" aria-hidden="true" ref="hoursModal">
            <div class="modal-dialog modal-md rounded-0" role="document">
                <div class="modal-content rounded-0">
                    <div class="modal-header rounded-0">
                        <h5 class="modal-title" id="workHourModalLabel">Darbo valandos</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="saveList">
                        <div class="modal-body">
                            <div class="row p-0">
                                <div class="col-lg-4">
                                    <label class="font-weight-bold">Pirmadienis</label>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Darbo pradžia</label>
                                        <input type="time" class="form-control rounded-0" v-model="days.monday_start">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Darbo pabaiga</label>
                                        <input type="time" class="form-control rounded-0" v-model="days.monday_end">
                                    </div>
                                </div>
                            </div>
                            <div class="row p-0">
                                <div class="col-lg-4">
                                    <label class="font-weight-bold">Antradienis</label>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Darbo pradžia</label>
                                        <input type="time" class="form-control rounded-0" v-model="days.tuesday_start">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Darbo pabaiga</label>
                                        <input type="time" class="form-control rounded-0" v-model="days.tuesday_end">
                                    </div>
                                </div>
                            </div>
                            <div class="row p-0">
                                <div class="col-lg-4">
                                    <label class="font-weight-bold">Trečiadienis</label>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Darbo pradžia</label>
                                        <input type="time" class="form-control rounded-0" v-model="days.wednesday_start">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Darbo pabaiga</label>
                                        <input type="time" class="form-control rounded-0" v-model="days.wednesday_end">
                                    </div>
                                </div>
                            </div>
                            <div class="row p-0">
                                <div class="col-lg-4">
                                    <label class="font-weight-bold">Ketvirtadienis</label>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Darbo pradžia</label>
                                        <input type="time" class="form-control rounded-0" v-model="days.thursday_start">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Darbo pabaiga</label>
                                        <input type="time" class="form-control rounded-0" v-model="days.thursday_end">
                                    </div>
                                </div>
                            </div>
                            <div class="row p-0">
                                <div class="col-lg-4">
                                    <label class="font-weight-bold">Penktadienis</label>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Darbo pradžia</label>
                                        <input type="time" class="form-control rounded-0" v-model="days.friday_start">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Darbo pabaiga</label>
                                        <input type="time" class="form-control rounded-0" v-model="days.friday_end">
                                    </div>
                                </div>
                            </div>
                            <div class="row p-0">
                                <div class="col-lg-4">
                                    <label class="font-weight-bold">Šeštadienis</label>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Darbo pradžia</label>
                                        <input type="time" class="form-control rounded-0" v-model="days.saturday_start">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Darbo pabaiga</label>
                                        <input type="time" class="form-control rounded-0" v-model="days.saturday_end">
                                    </div>
                                </div>
                            </div>
                            <div class="row p-0">
                                <div class="col-lg-4">
                                    <label class="font-weight-bold">Sekmadienis</label>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Darbo pradžia</label>
                                        <input type="time" class="form-control rounded-0" v-model="days.sunday_start">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Darbo pabaiga</label>
                                        <input type="time" class="form-control rounded-0" v-model="days.sunday_end">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary rounded-0" data-dismiss="modal">Išsaugoti</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    props: ['location', 'listing'],

    data(){
        return{
            days:{
                monday_start: null,
                monday_end: null,
                tuesday_start: null,
                tuesday_end: null,
                wednesday_start: null,
                wednesday_end: null,
                thursday_start: null,
                thursday_end: null,
                friday_start: null,
                friday_end: null,
                saturday_start: null,
                saturday_end: null,
                sunday_start: null,
                sunday_end: null,
                flexible: false,
            },
            hours: []
        }
    },
    mounted(){
        // this.getList()
        // this.generateHours()
        this.days = this.location
    },
    methods:{
        saveList(){
            let tmp = {
                days: this.days
            }
            axios.patch('/vartotojas/skelbimai/' + this.listing + '/vietoves/' + this.location.id + '/darbo-laikas', tmp).then(res => {
                // this.$refs.hoursModal.hide()
                console.log(res.data)
            }).catch(err => {
                console.log(err)
            })
        },
        generateHours(){
            let list = []

            for(let i = 0; i <= 9; i++){
                list.push('0' + i + ':00')
                list.push('0' + i + ':15')
                list.push('0' + i + ':30')
                list.push('0' + i + ':45')
            }

            this.hours = list
        }
    },
    watch:{
        days: {
            handler(val){
                this.saveList()
            },
            deep: true
        }
    }
}
</script>
