<template>
    <div>
        <a :href="'#location-' + location.id" data-toggle="modal" :data-target="'#location-' + location.id"><i class="fal fa-map-marker-alt mr-3"></i>{{ location.address }}</a>
         <div class="modal fade" :id="'location-' + location.id" tabindex="-1" :aria-labelledby="'locationLabel' + location.id" aria-hidden="true">
             <div class="modal-dialog modal-lg">
                 <div class="modal-content rounded-0">
                     <div class="modal-header">
                         <h5 class="modal-title" :id="'locationaLabel' + location.id">{{ location.name }}</h5>
                         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                             <span aria-hidden="true">&times;</span>
                         </button>
                     </div>
                     <div class="modal-body">
                         <iframe
                              width="300"
                              height="170"
                              frameborder="0"
                              scrolling="no"
                              marginheight="0"
                              marginwidth="0"
                              :src="'https://maps.google.com/maps?q=' + location.lat + ',' + location.long + '&t=&z=15&ie=UTF8&iwloc=&output=embed'"
                              >
                         </iframe>
                     </div>
                 </div>
             </div>
         </div>
    </div>
</template>

<script>
    export default{
        props: ['location'],
        data(){
            return {

            }
        },
        mounted(){

        }
    }
</script>
<style media="screen">
    iframe{
        width: 100%;
        min-height: 520px;
    }
</style>
