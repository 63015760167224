<template>
    <div>
        <vue-google-autocomplete
            id="map"
            classname="form-control rounded-0 shadow-sm form-control-lg"
            placeholder="Įveskite adresą"
            v-on:placechanged="getAddressData"
            :country="['lt']"
            types="geocode"
            required
        >
        </vue-google-autocomplete>
        <input type="hidden" name="street_number" v-model="address.street_number">
        <input type="hidden" name="street" v-model="address.street">
        <input type="hidden" name="postal_code" v-model="address.postal_code">
        <input type="hidden" name="city" v-model="address.city">
        <input type="hidden" name="county" v-model="address.county">
        <input type="hidden" name="country" v-model="address.country">
        <input type="hidden" name="address" v-model="address.address">
        <input type="hidden" name="url" v-model="address.url">
        <input type="hidden" name="lat" v-model="address.lat">
        <input type="hidden" name="long" v-model="address.long">
    </div>
</template>

<script>
    import VueGoogleAutocomplete from 'vue-google-autocomplete'
    export default{
        components:{
            'vue-google-autocomplete': VueGoogleAutocomplete
        },
        data(){
            return {
                address: {
                    street_number: null,
                    street: null,
                    postal_code: null,
                    city: null,
                    county: null,
                    country: null,
                    address: null,
                    url: null,
                    lat: null,
                    long: null
                }
            }
        },
        mounted(){

        },
        methods:{
            getAddressData(a, b){
                this.address = {
                    street_number: a.street_number,
                    street: a.route,
                    postal_code: a.postal_code,
                    city: a.locality,
                    county: a.administrative_area_level_1,
                    country: a.country,
                    address: b.formatted_address,
                    url: b.url,
                    lat: a.latitude,
                    long: a.longitude
                }
            }
        }
    }
</script>
