<template>
    <div>
        <notifications group="notifications" position="bottom right" />
        <div class="row mb-3 border-bottom" v-for="item, index in locations">
            <form @submit.prevent="updateLocation(item)" method="post" class="col-12">
                <div class="row">
                    <div class="col-lg-12 text-center text-lg-right">
                        <div class="form-group">
                            <button type="button" class="btn btn-outline-primary btn-sm rounded-0" @click="deleteLocation(index, item.id)" v-confirm><i class="fal fa-trash"></i></button>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Pavadinimas</label>
                            <input type="text" class="form-control rounded-0 shadow-sm form-control-lg" v-model="locations[index].name">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Adresas</label>
                            <vue-google-autocomplete
                                :id="'map-' + item.id"
                                classname="form-control rounded-0 shadow-sm form-control-lg"
                                placeholder="Įveskite adresą"
                                v-on:placechanged="setAddress"
                                :country="['lt']"
                                :value="item.address"
                                types="geocode"
                                :location_index="index"
                                required
                                disabled
                            >
                            </vue-google-autocomplete>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Telefonas</label>
                            <input type="phone" class="form-control rounded-0 shadow-sm form-control-lg" pattern="^(((\+[\d]{2,2})|\+\([\d]{2,2}\))|[0])?[0-9]+$" v-model="locations[index].phone">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>El. paštas</label>
                            <input type="email" class="form-control rounded-0 shadow-sm form-control-lg" v-model="locations[index].email">
                        </div>
                    </div>
                    <div class="col-12">
                        <listing-work-hours :listing="id" :location="item"></listing-work-hours>
                    </div>
                    <div class="col-12 mb-3 text-right">
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary rounded-0"><i class="fal fa-check"></i> Išsaugoti</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="row">
            <div class="col-12">
                <h2 class="mb-3">Pridėti naują adresą</h2>
                <vue-google-autocomplete
                    id="map"
                    classname="form-control rounded-0 shadow-sm form-control-lg"
                    placeholder="Įveskite adresą"
                    v-on:placechanged="getAddressData"
                    :country="['lt']"
                    types="geocode"
                    required
                >
                </vue-google-autocomplete>
                <!-- <input type="hidden" name="street_number" v-model="address.street_number">
                <input type="hidden" name="street" v-model="address.street">
                <input type="hidden" name="postal_code" v-model="address.postal_code">
                <input type="hidden" name="city" v-model="address.city">
                <input type="hidden" name="county" v-model="address.county">
                <input type="hidden" name="country" v-model="address.country">
                <input type="hidden" name="address" v-model="address.address">
                <input type="hidden" name="url" v-model="address.url">
                <input type="hidden" name="lat" v-model="address.lat">
                <input type="hidden" name="long" v-model="address.long"> -->
            </div>
        </div>
    </div>
</template>

<script>
    import VueGoogleAutocomplete from 'vue-google-autocomplete'
    export default{
        components:{
            'vue-google-autocomplete': VueGoogleAutocomplete
        },
        props: ['all_locations', 'id'],
        data(){
            return {
                locations: null,
                address: {
                    street_number: null,
                    street: null,
                    postal_code: null,
                    city: null,
                    county: null,
                    country: null,
                    address: null,
                    url: null,
                    lat: null,
                    long: null
                }
            }
        },
        mounted(){
        this.locations = this.all_locations
        },
        methods:{
            updateLocation(location){
                axios.patch('/vartotojas/skelbimai/' + this.id + '/vietoves/' + location.id, location).then(res => {
                    console.log(res.data)
                    this.$notify({
                        group: 'notifications',
                        title: 'Išsaugota',
                        text: 'Adresas sėkmingai išsaugotas!'
                    })
                }).catch(err => {
                    console.log(err)
                })
            },
            deleteLocation(index, id){
                axios.delete('/vartotojas/skelbimai/' + this.id + '/vietoves/' + id).then(res => {
                    console.log(res.data)
                    this.locations.splice(index, 1)
                }).catch(err => {
                    console.log(err)
                })
            },
            setAddress(a, b, location){
                // console.log(a)
                // console.log(b)
                let address = {
                    street_number: a.street_number,
                    street: a.route,
                    postal_code: a.postal_code,
                    city: a.locality,
                    county: a.administrative_area_level_1,
                    country: a.country,
                    address: b.formatted_address,
                    url: b.url,
                    lat: a.latitude,
                    long: a.longitude
                }

                let location_id = location.replace('map-', '')

                axios.patch('/vartotojas/skelbimai/' + this.id + '/vietoves/' + location_id, address).then(res => {
                    console.log(res.data)
                }).catch(err => {
                    console.log(err)
                })
            },
            getAddressData(a, b){
                let address = {
                    street_number: a.street_number,
                    street: a.route,
                    postal_code: a.postal_code,
                    city: a.locality,
                    county: a.administrative_area_level_1,
                    country: a.country,
                    address: b.formatted_address,
                    url: b.url,
                    lat: a.latitude,
                    long: a.longitude
                }
                axios.post('/vartotojas/skelbimai/' + this.id + '/vietoves', address).then(res => {
                    console.log(res.data)
                    this.locations.push(res.data)
                }).catch(err => {
                    console.log(err)
                })
            }
        }
    }
</script>
