<template>
  <div>
    <VueSlickCarousel :arrows="true" :dots="false" v-bind="settings" class="mt-4">
        <div class="col-12 mt-3" v-for="item in categories">
            <div class="card card-body text-center rounded-0 mh-200 homepage-card">
                <img :src="item.icon" :alt="item.name" height="55">
                <h3 class="fs-22 mt-3">{{ item.name }}</h3>
                <div class="form-group mb-3 actions">
                    <a :href="'/skelbimai/' + item.slug" class="btn btn-outline-primary btn-sm btn-block rounded-0"><i class="fal fa-arrow-right mr-2 ml-2"></i></a>
                </div>
            </div>
        </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

  export default {
    name: 'CategoriesSlick',
    components: { VueSlickCarousel },
    props: ['categories'],
    data(){
        return{
            settings: {
                "infinite": true,
                "slidesToShow": 4,
                "speed": 500,
                "rows": 2,
                "slidesPerRow": 1,
                "dots": false,
                "responsive": [
                {
                  "breakpoint": 1248,
                  "settings": {
                    "slidesToShow": 3,
                    "slidesToScroll": 3,
                  }
                },
            ]
            }
        }
    }
}
</script>
<style media="screen">
    .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
        opacity: 0.3;
        color: #3f5584;
        font-size: 26px;
    }
    .slick-prev:before, .slick-next:before{
        color: #3f5584;
        font-size: 26px;
    }
    .slick-next, .slick-prev{
        margin-top: 8px;
    }
</style>
