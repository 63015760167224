<template>
    <div>
        <div class="row">
            <div class="col-lg-3" v-for="item, index in previews">
                <div class="photo-preview shadow" :class="(uploading) ? 'loading' : ''" :style="{ backgroundImage: 'url(/storage/' + item.url + ')' }"></div>
                <!-- {{progressBars[index]}} INDEX: {{index}}
                <div class="progress mb-3" v-if="progressBars[index] != 100">
                    <div class="progress-bar" role="progressbar" :style="'width: ' + progressBars[index] + '%;'" :aria-valuenow="progressBars[index]" aria-valuemin="0" aria-valuemax="100">{{ progressBars[index] }}%</div>
                </div> -->
                <div class="form-group text-right">
                    <!-- <input type="text" name="" class="form-control form-control-sm">  -->
                    <button type="button" class="btn btn-outline-primary btn-sm" @click="removePhoto(index, item.id)"><i class="fa fa-trash"></i></button>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="form-group">
                    <input type="file" @change="getFiles" class="d-none" ref="file" accept="image/*" multiple/>
                    <button type="button" class="btn btn-outline-primary rounded-0" @click="$refs.file.click()"><i class="fal fa-image mr-2"></i> įkelti nuotraukas</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        props: ['id', 'gallery'],
        data(){
            return {
                formatedImages: [],
                currentPhoto: '',
                totalPhotos: 0,
                photos: [],
                previews: [],
                progressBars: [],
                uploading: false,
                newPhoto: null
            }
        },
        mounted(){

            this.previews = this.gallery
            // this.progressBars.push(ą)
            // if(this.gallery){
            //     this.gallery.forEach(item => {
            //         this.previews.push('/' + item.url)
            //     })
            // }
        },
        methods:{
            removePhoto(index, photo){
                axios.delete('/vartotojas/skelbimai/' + this.id +'/photo-delete/' + photo).then(res => {
                    this.previews.splice(index, 1)
                    console.log(res.data)
                }).catch(err => {
                    console.log(err.response)
                })

            },
            getFiles(e){
                let length = e.target.files.length
                let file  = null
                this.newPhoto = null

                for(var i = 0; i < length; i++){
                    this.photos.push(e.target.files[i])
                    this.newPhoto = e.target.files[i]
                    // this.previews.push(URL.createObjectURL(e.target.files[i]))

                    // this.progressBars.push(0)
                    this.uploadPhoto(i)
                }
            },
            uploadPhoto(index){
                this.uploading = true
                let data = new FormData()
                data.append('photo', this.newPhoto)
                data.append('listing', this.id)

                let config = {
                    header : {
                        'Content-Type' : 'multipart/form-data'
                    },
                    onUploadProgress: function(progressEvent) {
                        var percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                        // this.progressBars[index] = Math.floor((Math.random() * 100) + 1)
                    }.bind(this)
                }

                axios.post('/vartotojas/skelbimai/' + this.id +'/photo-upload', data, config).then(res => {
                    this.uploading = false
                    // this.previews = []
                    this.previews.push(res.data)
                    // res.data.forEach(item => {
                    //     this.previews.push('/' + item)
                    // })
                }).catch(err => {
                    console.log(err.response)
                })
            },
        },
        watch:{
            progressBars(e){
                // e.forEach((item, i) => {
                //     e[i] = e[i]
                // });

            }
        }
    }
</script>
<style media="screen">
    .photo-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 100%;
        width: 100%;
        margin-bottom: 20px;
        position: relative;
        background-size: cover;
    }
    .photo-preview:hover{
        /* opacity: 0.6; */
        cursor: pointer;
    }
    .photo-preview:hover:after{
        content: 'ištrinti';
        color: #fff!important;
        background: #465e8d;
        padding: 6px;
        position: absolute;
        top: 45%;
    }
    .loading{
        opacity: 0.6;
    }
</style>
