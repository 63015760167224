<template>
    <div>
         <lightbox :items="formatedImages" :cells="3"></lightbox>
    </div>
</template>

<script>
    export default{
        props: ['images'],
        data(){
            return {
                formatedImages: []
            }
        },
        mounted(){
            this.images.forEach(item => {
                this.formatedImages.push('/storage/' + item.url)
            })
        }
    }
</script>
<style media="screen">
    .lb-grid{
        height: 340px;
    }
    .lb-modal-img{
        left: 0;
        right: 0;
    }
</style>
