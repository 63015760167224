import Vue from 'vue'
import Lightbox from '@morioh/v-lightbox'
import VuejsDialog from 'vuejs-dialog';
import Notifications from 'vue-notification'

require('./bootstrap');

window.Vue = require('vue');

Vue.component('listing-gallery', require('./components/listing/gallery.vue').default);
Vue.component('listing-photos', require('./components/listing/photos.vue').default);
Vue.component('listing-autocomplete', require('./components/listing/google-autocomplete.vue').default);
Vue.component('listing-services', require('./components/listing/services.vue').default);
Vue.component('listing-location', require('./components/listing/location.vue').default);
Vue.component('listing-locations', require('./components/listing/locations.vue').default);
Vue.component('listing-rating', require('./components/listing/rating.vue').default);
Vue.component('listing-display-rating', require('./components/listing/display-rating.vue').default);
Vue.component('listing-breeds', require('./components/listing/breeds.vue').default);
Vue.component('listing-work-hours', require('./components/listing/work-hours.vue').default);
Vue.component('listing-hours', require('./components/listing/listing-hours.vue').default);

Vue.component('categories-slick', require('./components/categories.vue').default);

Vue.use(Lightbox);
Vue.use(VuejsDialog,{
    html: true,
    loader: true,
    okText: 'Tęsti',
    cancelText: 'Atšaukti',
    animation: 'fade',
    message: 'Ar tikrai norite atlikti šį veiksmą?'
});
Vue.use(Notifications)
// Vue.use(VueGoogleAutocomplete);

const app = new Vue({
    el: '#app',
});

//Custom code
$(document).ready(function(){
    $('#logoinput').change(function() {
      var file = $('#logoinput')[0].files[0].name;
      $('#logoname').text(file);
    });
})
$('#promo_points').on('show.bs.modal', function(e) {
    var listingID = $(e.relatedTarget).data('listing');

    $(e.currentTarget).find('input[name="listing_id"]').val(listingID);
});

$('#create_list_category_id').on('change', function(e) {
    let cat_id = $(this).children("option:selected").val()
    console.log(cat_id)
    if(cat_id == 1 || cat_id == 10 || cat_id == 9 || cat_id == 11){
        $('#vmvt_block').show()
        $('#vmvt').attr('required', true)
    } else {
        $('#vmvt_block').hide()
        $('#vmvt').attr('required', false)
    }
});

$('#filter_form select').change(function(event) {
    $('#filter_form').submit()
});
$('#filter_form input').change(function(event) {
    $('#filter_form').submit()
});
